import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledTextLink = styled(Link)`
  color: ${(props) => props.theme.textColor} !important;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
    transition: 0.2s;
    cursor: pointer;
  }
`;

export const StyledLink = styled(Link)`
  color: ${(props) =>
    props.color ? props.color : props.theme.linkColor} !important;
  text-decoration: none !important;

  a {
    color: #fff !important;
  }

  /* a:-webkit-any-link {
    color: ${(props) => props.theme.linkColor} !important;
    text-decoration: none !important;
  }

  a:link {
    color: ${(props) => props.theme.linkColor} !important;
    text-decoration: none !important;
  }
  a:visited {
    color: ${(props) => props.theme.linkColor} !important;
    text-decoration: none !important;
  } */
`;
